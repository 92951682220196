<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 부서 -->
          <c-dept 
            type="search" 
            label="LBLDEPT" 
            name="seniorDeptCd" 
            v-model="searchParam.seniorDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 선임자 -->
          <c-field 
            type="user" 
            label="LBL0010211" 
            name="seniorUserId" 
            v-model="searchParam.seniorUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 선임기간 -->
          <c-datepicker
            :range="true"
            name="period"
            label="LBL0010212"
            v-model="period"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!--선/해임 구분-->
          <c-select
            :editable="editable"
            :comboItems="dismissalFlagItems"
            type="search"
            label="LBL0010213"
            itemText="codeName"
            itemValue="code"
            name="dismissalFlag"
            v-model="searchParam.dismissalFlag">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <!--선해임 현황 목록-->
    <c-table
      ref="seniorDismissal"
      title="LBL0010214"
      tableId="seniorDismissal"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!--등록-->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="openPopup" />
          <!--검색-->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'senior-dismissal-status',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            //사업장명
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          // {
          //   name: 'revisionNum',
          //   field: 'revisionNum',
          //   label: 'Rev.',
          //   align: 'center',
          //   style: 'width:50px',
          //   sortable: true,
          // },
          // {
          //   name: 'seniorFieldName',
          //   field: 'seniorFieldName',
          //   label: '분야',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true,
          // },
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            //관련법규
            label: 'LBL0010215',
            align: 'left',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'seniorName',
            field: 'seniorName',
            //선임명
            label: 'LBL0010216',
            align: 'left',
            style: 'width:200px',
            type: "link",
            sortable: true,
          },
          {
            name: 'seniorDeptName',
            field: 'seniorDeptName',
            //부서
            label: 'LBLDEPT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'seniorUserName',
            field: 'seniorUserName',
            //선임자
            label: 'LBL0010211',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'seniorDate',
            field: 'seniorDate',
            //선임일
            label: 'LBL0010217',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'dismissalFlagName',
            field: 'dismissalFlagName',
            //선/해임
            label: 'LBL0010218',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: "dismissalDate",
            field: 'dismissalDate',
            //해임일
            label: 'LBL0010219',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          }
        ],
        data: [],
      },
      dismissalFlagItems: [
        { code: 'N', codeName: '선임' },
        { code: 'Y', codeName: '해임' },
      ],
      searchParam: {
        plantCd: '',
        seniorDeptCd: '',
        seniorUserId: '',
        startYmd: '',
        endYmd: '',
        dismissalFlag: null,
      },
      period: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.mdm.senior.status.list.url;
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPopup(row);
    },
    openPopup(result) {
      this.popupOptions.target = () => import(`${"./seniorDismissalStatusDetail.vue"}`);
      this.popupOptions.title = 'LBL0010220'; // 선해임 현황 상세
      this.popupOptions.param = {
        seniorStatusId: result ? result.seniorStatusId : '',
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
